import { useContext, useEffect } from 'react'
import { DidomiContext } from '../../../chapter/components/Didomi/DidomiContext'
import { DmpParameters } from '../../../chapter/components/DmpStateProvider/DmpStateProvider'
import { getContextualTargetingData } from '../../api/contextualTargetingApiClient'

declare global {
    interface Window {
        prmtvCtxTargetingValues: string[]
    }
}

export const useContextualTargeting = async (dmpParameters: DmpParameters | undefined) => {
    const { isConsentGivenForVendor, isReady } = useContext(DidomiContext)
    useEffect(() => {
        if (isReady) {
            const permutiveConsent = isConsentGivenForVendor('iab:361')
            if (!permutiveConsent) {
                if (dmpParameters) {
                    // in case these are present, get rid of them
                    delete dmpParameters.emailSha256Hash
                    delete dmpParameters.gender
                    delete dmpParameters.age
                    delete dmpParameters.wh_uuid
                }
                loadContextualKeywords(dmpParameters)
            }
        }
    }, [isConsentGivenForVendor, isReady, dmpParameters])
}

const loadContextualKeywords = async (dmpParameters: DmpParameters | undefined) => {
    const requestData = JSON.stringify({
        pageProperties: enrichClient(dmpParameters),
        url: document.URL,
    })
    try {
        const apiResponse = await getContextualTargetingData(requestData)
        const ctxcohorts = apiResponse.xandr || []
        window.prmtvCtxTargetingValues = ctxcohorts.concat('rts') // always include "rts"
    } catch (e) {
        console.error('Permutive contextual api was not available', e)
    }

    function enrichClient(dmpParameter: DmpParameters | undefined) {
        try {
            const clientObject = {
                client: {
                    url: document.URL,
                    referrer: document.referrer,
                    type: 'web',
                    user_agent: navigator.userAgent,
                    domain: window.location.hostname,
                    title: document.title,
                },
            }
            if (typeof dmpParameter === 'object' && !Array.isArray(dmpParameter)) {
                return Object.assign({}, dmpParameter, clientObject)
            } else {
                return clientObject
            }
        } catch (e) {
            console.error('enriching client failed', e)
            return {}
        }
    }
}
